import React from "react";

// Admin Imports
import MainDashboard from "views/admin/dashboard";
import Management from "views/admin/management";
import Charge from "views/admin/charge";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "แดชบอร์ด",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "รายงานยอดขาย",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "charge",
    component: <Charge />,
  },
  {
    name: "จัดการสมาชิก",
    layout: "/admin",
    path: "management-user",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Management />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
