import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./features/users/authSlice";
import { todoApi } from "./services/todos/todoApi";
import { loginApi } from "./services/admin/adminApi";
import { chargeAPI } from "./services/charge/chargeAPI";
import { dashboardApi } from "./services/dashboard/dashboardApi";
import { managementApi } from "./services/management/managementApi";

export const store = configureStore({
  reducer: {
    // authState: authReducer,
    [todoApi.reducerPath]: todoApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [chargeAPI.reducerPath]: chargeAPI.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [managementApi.reducerPath]: managementApi.reducer
  },
  devTools: process.env.REACT_APP_MODE !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      todoApi.middleware,
      loginApi.middleware,
      chargeAPI.middleware,
      dashboardApi.middleware,
      managementApi.middleware
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
